import { v7 } from "uuid";

export async function fetchRequest(
  url: string,
  requestData: RequestInit = {},
) {
  let requestDataC = { ...requestData };
  if (!requestDataC.headers) requestDataC.headers = {};
  requestDataC.headers = Object.assign(requestDataC.headers, {
    "X-Request-ID": v7(),
  });
  requestDataC.credentials = "omit";
  return fetch(url, requestDataC);
}

export async function fetchJSONRequest<T>(
  url: string,
  requestData: RequestInit = {},
) {
  let requestDataC = { ...requestData };
  if (!requestDataC.headers) requestDataC.headers = {};
  requestDataC.headers = Object.assign(requestDataC.headers, {
    "Content-Type": "application/json",
    Accept: "application/json",
  });
  return fetchRequest(url, requestDataC).then((response) => {
    return response.json() as T;
  });
}

export async function fetchProtoRequest(
  url: string,
  requestData: RequestInit = {},
) {
  let requestDataC = { ...requestData };
  if (!requestDataC.headers) requestDataC.headers = {};
  requestDataC.headers = Object.assign(requestDataC.headers, {
    Accept: "application/x-protobuf",
  });
  return fetchRequest(url, requestDataC).then((response) => {
    return response.arrayBuffer();
  });
}